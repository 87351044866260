@use "./styles/index.scss";

.singularity-app {
  width: 100%;
  height: 100%;
  background-color: index.$app-background-color;

  .app-header {
    width: 100%;
    height: index.$app-header-height;
  }

  .app-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(100% - index.$app-header-height);
  }
}
