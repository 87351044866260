@use "./styles/Variables";

.app {
  width: 100%;
  height: 100%;

  .app-body {
    width: 100%;
    height: calc(100% - #{Variables.$app-header-height});
  }
}
