.logo-container {
  width: fit-content;
  height: 100%;
  padding: 4px;

  .singularity-logo {
    width: fit-content;
    height: 100%;
    fill: #f6f7f9;
  }
}
