@mixin module {
  width: 100%;
  height: 100%;

  .module-body {
    @include module-body;
  }
}

@mixin module-body {
  width: 100%;
  height: 100%;
  padding: 16px;
}
