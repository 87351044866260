// @import "~normalize.css/normalize.css";

// // import these first because they're the base layer
// @import "~@blueprintjs/core/lib/css/blueprint.css";
// @import "~@blueprintjs/datetime/lib/css/blueprint-datetime.css";
// @import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
// @import "~@blueprintjs/popover2/lib/css/blueprint-popover2.css";
// @import "~@blueprintjs/select/lib/css/blueprint-select.css";
// @import "~@blueprintjs/table/lib/css/table.css";

html,
body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  #root {
    width: 100%;
    height: 100%;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
