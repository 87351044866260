.app-header {
  display: flex;
  flex-direction: row;

  .logo-container {
    width: 240px;
  }

  .app-header-left-section {
    display: flex;
    flex-direction: row;
  }

  .app-header-right-section {
    display: flex;
    flex-direction: row;
  }
}
